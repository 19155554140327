<template>
  <div class="pipeline">
    <div class="banner">
      <div class="title">Our Pipeline</div>
      <div class="text">
        Our proprietary antibody drug discovery engine has helped establish a solid portfolio of immuno-oncology antibody drug candidates against novel and challenging targets.
      </div>
    </div>
    <div class="content">
      <div v-for="(item,index) in line_list" :key="index" class="item">
        <div class="i_text">
          <div class="title">{{item.name}}</div>
          <div class="cation text">Indication: {{item.indication}} </div>
          <div class="info">{{item.info}}</div>
          <div v-if="item.info ? false : true" class="small"></div>
        </div>
        <div class="icon text-white">
          <div :style="{width:`${item.width}`}" class="d-flex align-items-center">
            <img class="img" :src="item.icon" alt="">
            <span class="text new-text">{{item.icon_name}}</span>
          </div>
        </div>
      </div>
      <div v-show="false" id="views"></div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
import * as echarts from 'echarts'

export default {
  data () {
    return {
      line_list: [
        {
          name: 'XBH25/GV20-0251',
          info: 'IGSF8',
          indication: 'Solid tumors',
          width: '80%',
          icon: require('../assets/pipeline/Phase1.png'),
          icon_name: 'Phase 1'
        }, {
          name: 'XBH40 biparatopic ADC',
          info: 'B7H3',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH102',
          info: 'Undisclosed',
          indication: 'GI tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'New programs',
          info: 'Undisclosed',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH40',
          info: 'B7H3',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH41',
          info: 'LILRB2',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH52',
          info: 'KIR3DL2',
          indication: 'Oncology, Autoimmune',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH54',
          info: 'TGFb',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        }, {
          name: 'XBH62',
          info: 'NECTIN4',
          indication: 'Solid tumors',
          width: '40%',
          icon: require('../assets/pipeline/Discovery.png'),
          icon_name: 'Discovery'
        },
      ]
    }
  },
  mounted () {
    var chartDom = document.getElementById('views');
    var myChart = echarts.init(chartDom);
    var option;

    const labelRight = {
      position: 'right'
    };
    option = {
      title: {
        text: 'Bar Chart with Negative Value'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        top: 80,
        bottom: 30
      },
      xAxis: {
        type: 'value',
        position: 'top',
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'category',
        axisLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
        splitLine: { show: false },
        data: [
          'ten',
          'nine',
          'eight',
          'seven',
          'six',
          'five',
          'four',
          'three',
          'two',
          'one'
        ]
      },
      series: [
        {
          name: 'Cost',
          type: 'bar',
          stack: 'Total',
          label: {
            show: true,
            formatter: '{b}'
          },
          data: [
            { value: -0.07, label: labelRight },
            { value: -0.09, label: labelRight },
            0.2,
            0.44,
            { value: -0.23, label: labelRight },
            0.08,
            { value: -0.17, label: labelRight },
            0.47,
            { value: -0.36, label: labelRight },
            0.18
          ]
        }
      ]
    };

    option && myChart.setOption(option);

  },
  methods: {

  },
  components: {
    Footers
  }
}
</script>
<style lang="scss" scoped>
$padding-left: 50px;
.pipeline {
  #views {
    width: 90%;
    height: 800px;
    margin: 0 auto;
  }
  .banner {
    height: 652px;
    background: url(../assets/pipeline/headline_bg.jpg) no-repeat center
      center/cover;
    padding: 100px 150px 100px 30px;
    color: #fff;
    .title {
      margin-bottom: 40px;
    }
  }
  .content {
    padding: 80px 0;
    .item {
      padding: 40px 20px;
      &:nth-of-type(odd) {
        background: #fff;
      }
      &:nth-of-type(even) {
        background: #f9f9f9;
      }
      .info {
        color: #3852a3;
        font-style: italic;
        margin: 20px 0;
      }
      .text {
        font-size: 30px;
        font-weight: bold;
      }
      .small {
        height: 50px;
      }
      .icon {
        background: transparent;
        overflow: hidden;
        animation: icon 1s ease forwards;
        @keyframes icon {
          from {
            transform: translateX(-100%);
          }
          to {
            background: #bec6de;
            transform: none;
          }
        }
        & > div {
          padding: 10px 0 10px 20px;
          background: #3852a3;
          position: relative;
          border-radius: 0 20px 20px 0;
          animation: move 1s ease 1s forwards;
          transform: translateX(-120%);
          @keyframes move {
            from {
              transform: translateX(-100%) scaleX(0);
            }
            to {
              transform: none;
            }
          }
          &:after {
            content: '';
            position: absolute;
            right: -12%;
            top: 50%;
            transform: translateY(-50%);
            width: 80px;
            height: 100%;
            background: url(../assets/pipeline/Triangle.png) no-repeat center
              center/cover;
          }
        }

        img {
          width: 64px;
          height: 64px;
          margin-right: 10px;
        }
      }
      &:nth-of-type(2) {
        .icon > div {
          animation-delay: 1.2s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(3) {
        .icon > div {
          animation-delay: 1.4s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(4) {
        .icon > div {
          animation-delay: 1.6s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(5) {
        .icon > div {
          animation-delay: 1.8s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(6) {
        .icon > div {
          animation-delay: 2.0s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(7) {
        .icon > div {
          animation-delay: 2.2s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(8) {
        .icon > div {
          animation-delay: 2.4s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(9) {
        .icon > div {
          animation-delay: 2.6s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(10) {
        .icon > div {
          animation-delay: 2.8s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(11) {
        .icon > div {
          animation-delay: 3.0s;
          &::after {
            right: -24%;
          }
        }
      }
      &:nth-of-type(12) {
        .icon > div {
          animation-delay: 3.2s;
          &::after {
            right: -14%;
          }
        }
      }

    }
  }
  .title {
    font-weight: bold;
    font-size: 50px;
  }
  .text {
    font-size: 40px;
  }
  .info {
    font-size: 30px;
  }
  .new-text{
    white-space: nowrap;
  }
  .small {
    font-size: 32px;
    font-family: Helvetica-Oblique, Helvetica;
    font-weight: normal;
    color: #3852a3;
    line-height: 44px;
  }
}
</style>
